<script>
    
    // properties
    export let canvas;
    export let camera_helper;
    export let camera, renderer, scene, controls;
    export let ready = false;
    
    // import config
    import { fov, near, far, WIDTH_INCREASE_RATIO, EARTH_RADIUS_PX, ENDPOINT_BORDERS, ENDPOINT_RASTERS } from './config.js';
    
    // import libs
    import { onMount } from "svelte";
    import * as THREE from 'three';
    import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
    
    // import scripts
    import { Camera } from "./Camera.js";
    import { buildEarth, buildBorders, buildCities, load_image } from './scripts.js';
    
    // set orientation
    THREE.Object3D.DEFAULT_UP.set(0.0, 0.0, 1.0);

    // Define the material for the border lines.
    const material_borders = new THREE.LineBasicMaterial({ color: "#000" });
    const material_borders_highlighted = new THREE.LineBasicMaterial({ color: "#ff0000" });


    
    // init
    function init(){
        
        // grab canvas
        canvas = document.querySelector('#map');
        
        // get bounding box size
        const bounding_box = canvas.getBoundingClientRect();
        const { width, height } = bounding_box;
        
        // compute camera parameters
        const aspect = width / (1.0 * height);
        
        // init elements
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera( fov, aspect, near, far );
        renderer = new THREE.WebGLRenderer({ alpha: true, canvas: canvas, antialias: true });
        
        // set renderer attributes
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        
        // create control
        controls = new OrbitControls( camera, renderer.domElement );
        
        // disable user controls
        controls.enabled = false;

        // init camera helper
        camera_helper = new Camera(scene, camera, renderer, controls, fov, aspect);
        
        // init position
        camera_helper.move_to_latlng(49.9935, 36.2304, 2.0);
    }

   
    async function populate_with_vectors(){
        
        // build earth
        const object_earth = buildEarth();
        
        // build borders of countries
        const object_borders = await buildBorders(ENDPOINT_BORDERS, material_borders);
        
        // build cities
        const object_cities = await buildCities();

        // add earth to scene
        scene.add(object_earth);
        scene.add(object_borders); 
        scene.add(object_cities);   
    }


    async function populate_with_rasters(){

        // load rasters json
        const rasters = await fetch(ENDPOINT_RASTERS).then(response => response.json());

        // skip if no rasters provided
        if (rasters === null || rasters === undefined || !Array.isArray(rasters) || rasters.length === 0) {
            return [];
        }

        // draw rasters
        for (const raster of rasters) {

            // destructure
            const { filepath, bbox, radius_offset, opacity } = raster;

            // convert bbox to coordinates
            const coordinates = [ [bbox[0], bbox[1]], [bbox[0], bbox[3]], [bbox[2], bbox[3]], [bbox[2], bbox[1]], [bbox[0], bbox[1]] ];

            // load
            const object = await load_image(filepath, radius_offset, coordinates, true, opacity );

            // add to scene
            scene.add( object );
        }
    }
        
    
    function reset_countries(){

        // get all objects that have the input countries as properties
        const object_borders = scene.children[1];
        
        // reset material
        object_borders.children.forEach(child => child.material = material_borders);
    }


    export const highlight_countries = (countries) => {

        // reset
        reset_countries();

        // get all objects that have the input countries as properties
        const object_borders = scene.children[1];
        const objects_countries = object_borders.children.filter(child => countries.includes(child.properties['name']));

        // set new material for all objects
        objects_countries.forEach(obj => obj.material = material_borders_highlighted)
    }


    onMount(async () => {
        
        // init
        init();
        
        // populate
        await populate_with_vectors();
        
        // render
        setTimeout(() => {            

            // render
            renderer.render( scene, camera );

            // set ready flag
            ready = true;

            // set opacity
            canvas.parentElement.style.opacity = 1;
            
        }, 500);
    })   

</script>


<div class="map-container">
    <canvas id="map"></canvas>
</div>


<style>

    .map-container {
        z-index: 0;
        width: 100% !important;
        height: 100vh !important;
        background-color: #eee !important;
        min-width: unset !important;
        min-height: unset !important;
        max-width: unset !important;
        max-height: unset !important;
        padding: 0px !important;
        opacity: 0;
        transition: opacity 1.5s ease-in-out;
    }
    
    canvas {
        width: 100% !important;
        height: 100vh !important;
        background-color: #eee !important;
        padding: 0px !important;
        min-width: unset !important;
        min-height: unset !important;
        max-width: unset !important;
        max-height: unset !important;
    }
    
</style>
