<!-- 
    Wrapper to lazy load images when they become in view. 
    This is useful for large images that are not needed until the user scrolls to them.
-->
<script>

    // import libs
    import { onMount } from "svelte";

    // properties
    export let src = '';
    let container = null;
    let visible = false;

    // setup intersection observer
    onMount(() => {

        // init observers
        const observer_in_view = new IntersectionObserver(async function(entries) {
            // isIntersecting is true when element and viewport are overlapping
            // isIntersecting is false when element and viewport don't overlap

            // if overlap, set as displayed section
            if(entries[0].isIntersecting) {
                visible = true;
            }

        }, { threshold: [0.0] });

        // set target
        observer_in_view.observe(container);
    })

</script>


<div bind:this={container}>
    {#if visible}
        <img alt="illustration" src={src}/>
    {/if}
</div>

<style>

    div {
        width: 100%;
        text-align: center;
        margin: 0px;
        padding: 0px 0px 8px;
    }

    img {
        max-width: 100%;
    }

</style>