'use strict';


// --- Camera ---
// fov — Camera frustum vertical field of view.
export const fov = 50;

// near — Camera frustum near plane.
export const near = 0.1;

// far — Camera frustum far plane.
export const far = 1000;


// --- Shift the camera to the right if >1 ---
export const WIDTH_INCREASE_RATIO = 1.0;


// --- Objects Properties ---
export const EARTH_RADIUS_PX = 228;
export const BACKGROUND_COLOR = '#fff';
export const TEXT_SIZE = 0.1;


// --- Endpoints ---
export const ENDPOINT_BORDERS = `data/borders.json.zip`;
export const ENDPOINT_RASTERS = `data/rasters.json`;
export const ENDPOINT_CITIES = `data/cities.json`;
export const ENDPOINT_FONT = 'assets/fonts/noto-sans-regular.json';

