<script>

    // import libs
    import { onMount } from 'svelte';
    import { scroller } from './scroller.js';
    import { load_json } from './libs/dataloader.js';

    // import configuration
    const ENDPOINT_CONTENT = `data/content.json`;

    // import components
    import Map from './components/Map/Map.svelte';
    import Image from './components/Image.svelte';
    import Background from "./Background.svelte";

    // Map properties
    let camera_helper;
    let highlight_countries;
    let map_ready;

    // Scroller properties
    let content;
    let content_ready = false;


    // if orientation changes, reload page
    window.addEventListener("orientationchange", function(){
        window.location.reload();
    }); 
    

    function init_scroller() {

        // create callbacks
        const callbacks = [...document.querySelectorAll('.steps section')].map(section => {
            return () => {

                // if doesnt have id, we stop here
                if (!section.hasAttribute("data-id")) {
                    console.log('INFO: Section does not have "id" attribute');
                    return;
                }

                // get the content for this id
                const datum = content.filter(d => +d['id'] === +section.dataset['id'])[0];
                
                // if nothing was returned, stop here
                if (datum === undefined || datum === null) {   
                    console.log('INFO: Section could not be retrieved based on its "id" attribute');
                    return;
                }

                // destructure data of section
                const { latitude, longitude, zoom, countries } = datum;

                // move camera over map
                if (map_ready === true && latitude !== undefined && camera_helper) camera_helper.animate_to_latlng(+latitude, +longitude, +zoom);

                // highlight countries
                if (map_ready === true && countries !== undefined && Array.isArray(countries) && countries.length > 0 && highlight_countries) highlight_countries(countries);
            };
        })

        // initialize the scroller with the callbacks
        scroller([callbacks]).initialize();
    }
    

    // on load
    onMount(async () => {

        // load content json
        const _content = await load_json(ENDPOINT_CONTENT);

        // check if content is valid
        if (_content === undefined || _content === null || !Array.isArray(_content) || _content.length === 0) {
            console.log('ERROR: Content is not valid');
            return;
        }

        // set content
        content = _content;
        content_ready = true;

        setTimeout(() => {

            // initialize scroller
            init_scroller();

        }, 300);
    })

</script>



<section class="full-page-section" style="z-index: 1 !important;">

    <!-- Animated Background -->
    <div class="titleImage">
        <Background/>
    </div>
    
    <div class="captionholder">
        <h1 class="caption mainTitle">Jean-Romain Roy</h1>
        <h2 class="caption secondaryTitle">Portfolio</h2>
        
        <br><br>

        <p class="caption" style="width: 66vw;">
            Over the last few years, I have worked with governments, UN agencies, international NGOs, media, and civil society organisations to develop and implement innovative data collection systems in conflict- and crisis- affected areas.
            <br><br>
            <a href = "mailto: jeanromainroy@protonmail.com">jeanromainroy@protonmail.com</a>
        </p>
    </div>
</section>




<section class="viz-section">

    <!-- TEXT -->
    <div class="steps">

        <div style="height: 8px;"></div>

        {#if content_ready === true}
        {#each content as section}
            <section data-id={section.id}>
                <h1>{section.title}</h1>
                <h2>{section.subtitle}</h2>
                <p>{@html section.text}</p>

                <div style="text-align: center;">
                    {#each section.links as link}
                        <p><a href={link.url} target="_blank" rel="noreferrer">{link.name}</a></p>
                    {/each}
                </div>

                <!-- picture if present -->
                {#if section.picture}
                    <Image src={section.picture} />
                {/if}
            </section>
        {/each}
        {/if}
    </div>
    
    <!-- Viz -->
    <div class="viz" id="viz">

        {#if content_ready === true}

        <!-- Map -->
        <Map
            bind:camera_helper={camera_helper}
            bind:highlight_countries={highlight_countries}
            bind:ready={map_ready}
        />

        {/if}
    </div>

</section>


<style>

</style>
