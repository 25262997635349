'use strict';

// import configuration
import { EARTH_RADIUS_PX, TEXT_SIZE, BACKGROUND_COLOR, ENDPOINT_FONT, ENDPOINT_CITIES } from './config.js';

// import libs
import * as THREE from 'three';
import { Earcut } from 'three/src/extras/Earcut.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';

// import scripts
import { load_json, load_zipped_json } from '../../libs/dataloader.js';
import { vertex, bbox_area, coordinates_to_sphere_parameters } from './libs/geometry.js';
import { pairs } from './libs/datamanipulation.js';


export async function load_font( front_url = ENDPOINT_FONT ){

    // init
    let font = null;

    // instantiate loader
    const loader = new FontLoader();

    // load font
    await new Promise((resolve) => {
        loader.load( front_url, function(_font) {
            font = _font;
            resolve();
        }) 
    }) ;
    
    return font;
}


export function vector_to_3d_geometry(vector, radius = EARTH_RADIUS_PX) {

    // destructure
    const { geometry } = vector;

    // init
    let triangles = [];

    // build object
    geometry['coordinates'].forEach(MultiPolygon => {

        // check type
        if (geometry['type'] === 'Polygon') {
            MultiPolygon = [MultiPolygon]
        }

        // init
        let data = [];
        let points = [];
        let holes_indices = MultiPolygon.length > 1 ? [] : null;

        // sort by bounding box area (decreasing order)
        MultiPolygon.sort((a, b) => {
            return bbox_area(b) - bbox_area(a)
        });

        // flatten the array
        MultiPolygon.forEach((Polygon, i) => {

            // is a hole
            if (i > 0) holes_indices.push(data.length / 3.0);

            // close
            // if (Math.abs(Polygon[0][0] - Polygon[Polygon.length-1][0]) > 0.01){
            //     Polygon = [...Polygon, Polygon[0]]
            // }

            Polygon.forEach(([lng, lat]) => {
                const point = vertex([lng, lat], radius);
                const { x, y, z } = point;
                data.push(x);
                data.push(y);
                data.push(z);
                points.push(point);
            });
        });

        // run triangulation
        const indices = Earcut.triangulate(data, holes_indices, 3);

        // format triangles as (x1, y1, z1, x2, y2, z2, ...)
        indices.map(index => points[index]).forEach(triangle => triangles.push(triangle));
    });

    // return geometry
    return triangles;
}


export function vector_to_2d_geometry(vector) {

    // destructure
    const { geometry } = vector;

    // init
    let polygons = [];
    let holes = [];

    // build object
    geometry['coordinates'].forEach(MultiPolygon => {

        // check type
        if (geometry['type'] === 'Polygon') {
            MultiPolygon = [MultiPolygon]
        }

        // sort by bounding box area (decreasing order)
        MultiPolygon.sort((a, b) => {
            return bbox_area(b) - bbox_area(a)
        });

        // flatten the array
        MultiPolygon.forEach((Polygon, i) => {

            // is a hole
            if (i > 0) {
                holes.push(Polygon);
            } else {
                polygons.push(Polygon);
            }
        });
    });

    // return geometry
    return { polygons, holes };
}


export function tessellate_vectors(vectors, radius = EARTH_RADIUS_PX){

    // check
    if (vectors === undefined || vectors === null || !Array.isArray(vectors) || vectors.length === 0) return [];

    // init
    let df = {};

    for (const vector of vectors) {

        // destructure
        const { properties } = vector;

        // compute
        const triangles_2d = vector_to_2d_geometry(vector);
        const triangles_3d = vector_to_3d_geometry(vector, radius);
        const geometry_3d = new THREE.BufferGeometry().setFromPoints(triangles_3d)

        // init
        df[properties['DATE']] = {
            'geometry': geometry_3d,
            'triangles_2d': triangles_2d
        }
    }

    return df;
}


export function build_LineString(feature, material, radius = EARTH_RADIUS_PX) {

    // extract coordinates
    const points = feature['geometry']['coordinates'];

    // project each point
    const points_projected = points.map(point => vertex(point, radius));

    // pair points to create lines
    const lines = pairs(points_projected)

    // create geometry
    const geometry = new THREE.BufferGeometry().setFromPoints( lines );

    // build object
    const object_line = new THREE.LineSegments(geometry, material )
    
    // set properties
    object_line['properties'] = feature['properties'];

    return object_line;
}


export async function buildCities(radius = EARTH_RADIUS_PX + 0.01 ){

    // Define the material for the border lines.
    const material = new THREE.MeshBasicMaterial( { color: "#000" } );

    // Create an Object3D for the cities.
    const object = new THREE.Object3D();
    
    // Load the JSON data for the cities.
    const asset = await load_json(ENDPOINT_CITIES);
    if (!asset) return;
    
    // Load the font
    const font = await load_font();

    // go through
    asset.forEach(city => {

        // destructure
        const { name, latitude, longitude, offset_label_latitude, offset_label_longitude } = city;

        // check if offset_label_latitude is defined and contains a float number
        let offset_latitude = 0.0;
        let offset_longitude = 0.0;
        if (offset_label_latitude !== undefined && parseFloat(offset_label_latitude)) {
            offset_latitude = parseFloat(offset_label_latitude);
        }
        if (offset_label_longitude !== undefined && parseFloat(offset_label_longitude)) {
            offset_longitude = parseFloat(offset_label_longitude);
        }

        // project lat/lng
        const point = vertex([longitude, latitude], radius);
        const point_offset = vertex([longitude + 0.03 + offset_longitude, latitude + offset_latitude], radius);

        // create sphere
        const geometry = new THREE.SphereGeometry(0.05, 12, 12);
        const sphere = new THREE.Mesh( geometry, material );

        // create text
        const geometry_text = new TextGeometry( name.toUpperCase(), {
            font: font,
            size: TEXT_SIZE,
            height: 0.001,
            curveSegments: 12
        } );    
        const text = new THREE.Mesh( geometry_text, material );

        // set position
        sphere.position.set(point['x'], point['y'], point['z']);
        text.position.set(point_offset['x'], point_offset['y'], point_offset['z']);

        // rotate
        const { x, y, z } = vertex([longitude, latitude], EARTH_RADIUS_PX * 1.5);
        text.lookAt(x, y, z);

        // add
        object.add(sphere);
        object.add(text);
    })

    return object;
}


/**
* Function to build a 3D representation of the Earth.
*/
export function buildEarth(radius = EARTH_RADIUS_PX) {
    
    // Define the material for the Earth's surface.
    const material = new THREE.MeshBasicMaterial({ color: BACKGROUND_COLOR });
    
    // Define the geometry of the Earth's shape.
    const geometry = new THREE.SphereGeometry(radius, 96, 96);
    
    // Create a 3D mesh for the Earth using the defined material and geometry.
    const object = new THREE.Mesh(geometry, material);
    
    return object;
}


/**
* Function to build borders of countries
*/
export async function buildBorders(endpoint, material, radius = EARTH_RADIUS_PX + 0.1) {

    // Create an Object3D for the borders.
    const object = new THREE.Object3D();
    
    // Load the JSON data for the borders.
    const asset = await load_zipped_json(endpoint);
    if (!asset) return;

    // Create an array of lines from the JSON data.
    let lines = [];
    let properties = [];
    asset["features"].forEach((feature, i) => {
        feature["geometry"]["coordinates"].forEach(obj => {
            if (feature["geometry"]["type"] === "MultiPolygon") {
                properties.push(feature['properties']);
                lines.push(obj[0])
            }
            if (feature["geometry"]["type"] === "Polygon") {
                properties.push(feature['properties']);
                lines.push(obj);
            }            
        });
    });
    
    // Build each line and add it to the borders object.
    lines.forEach((line, i) => {
        const feature = { type: "LineString", properties: properties[i], geometry: { coordinates: line } };
        const lineObject = build_LineString(feature, material, radius);
        object.add(lineObject);
    });

    return object;
}

    
export const load_image = async (url, radius_offset = 0.0, coordinates, opacity = 1.0 ) => {

    // init
    let texture;
    const loader = new THREE.TextureLoader();

    // load image as texture
    await new Promise((resolve) => {
        loader.load( url, function(_texture) {
            texture = _texture;
            resolve();
        });
    });

    // convert coordinates to sphere parameters
    const { phiStart, phiLength, thetaStart, thetaLength } = coordinates_to_sphere_parameters(coordinates);

    // use the texture for material creation
    const material = new THREE.MeshBasicMaterial( { map: texture, transparent: true, opacity: 0.3 } );

    // create object
    const geometry = new THREE.SphereGeometry( EARTH_RADIUS_PX + radius_offset, 128, 128, phiStart, phiLength, thetaStart, thetaLength );
    const object = new THREE.Mesh( geometry, material );

    // rotate to start at north pole and london
    object.rotation.x = Math.PI / 2.0;
    object.rotation.y = Math.PI;

    return object;
}

