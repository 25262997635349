'use strict';


export function distance_between_points_3D(x0, y0, z0, x1, y1, z1){
    return Math.sqrt( Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2) + Math.pow(z1 - z0, 2) );
}


export function angle_between_two_points_3D(x1, y1, z1, x2, y2, z2) {
    // spherical coordinates, with sphere centered at (0, 0, 0)

    // get norm
    const norm_1 = Math.sqrt(Math.pow(x1, 2) + Math.pow(y1, 2) + Math.pow(z1, 2));
    const norm_2 = Math.sqrt(Math.pow(x2, 2) + Math.pow(y2, 2) + Math.pow(z2, 2));

    // dot product
    const dot_product = x1 * x2 + y1 * y2 + z1 * z2;

    // compute 
    const value = dot_product / ( norm_1 * norm_2);

    // get the angle between current position and target position
    const angle_rad = Math.acos(value);
    const angle_deg = (angle_rad / (2.0 * Math.PI)) * 360.0

    return { rad: angle_rad, deg: angle_deg }
}


/**
 * Two objects. A sphere offset from the origin. A line starting from the origin and crossing the sphere's center. 
 * This function returns the furthest point where the two intersect. 
 */
export function intersection_of_line_and_sphere(cx, cy, cz, radius) {

    // init
    let t_final = 0.0;
    let last_diff = Infinity;
    const step = 0.0001;

    // brute force find intersection
    for (let t=1.0 ; t<2.0 ; t+=step) {

        // increment line position
        const line_x = cx * t;
        const line_y = cy * t;
        const line_z = cz * t;

        // should =0 if the point lies on the sphere
        const diff = Math.abs(Math.pow(line_x - cx, 2) + Math.pow(line_y - cy, 2) + Math.pow(line_z - cz, 2) - Math.pow(radius, 2));

        // if diff increases, we stop
        if (diff < last_diff) {
            last_diff = diff;
            t_final = t;
        }
    }

    return {
        'x': cx * t_final,
        'y': cy * t_final,
        'z': cz * t_final
    }
}


// Converts a point [longitude, latitude] in degrees to a THREE.Vector3.
export function vertex(point, radius = EARTH_RADIUS_PX) {
    
    var lambda = point[0] * Math.PI / 180,
        phi = point[1] * Math.PI / 180,
        cosPhi = Math.cos(phi);

    return {
        'x': radius * cosPhi * Math.cos(lambda),
        'y': radius * cosPhi * Math.sin(lambda),
        'z': radius * Math.sin(phi)
    }
}


export function inv_vertex(x, y, z) {

    // compute params
    const radius = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    const phi = Math.asin(z / radius);
    const cosPhi = Math.cos(phi);
    const lambda = Math.acos(x / (radius * cosPhi));

    // reprojet
    const lng = lambda * (180 / Math.PI);
    const lat = phi * (180 / Math.PI);

    return {
        'latitude': lat,
        'longitude': lng
    }
}


export function bbox_area(coordinates){

    // get max/min
    const max_lng = Math.max(...coordinates.map(([lng, lat]) => +lng));
    const max_lat = Math.max(...coordinates.map(([lng, lat]) => +lat));
    const min_lng = Math.min(...coordinates.map(([lng, lat]) => +lng));
    const min_lat = Math.min(...coordinates.map(([lng, lat]) => +lat));

    // compute diff
    const diff_lng = max_lng - min_lng;
    const diff_lat = max_lat - min_lat;

    // compute area
    const area = diff_lng * diff_lat;

    return area
}


/**
 * ONLY WORKS WHEN SPHERE GEOMETRY IS ROTATED: 
 *      object.rotation.x = Math.PI / 2.0;
 *      object.rotation.y = Math.PI;
*/
export function coordinates_to_sphere_parameters(coordinates){

    // extract min/max latitudes and longitudes
    const lngs = coordinates.map(d => d[0]);
    const lats = coordinates.map(d => d[1]);
    const max_lat = Math.max(...lats);
    const min_lat = Math.min(...lats);
    const max_lng = Math.max(...lngs);
    const min_lng = Math.min(...lngs);

    // LONGITUDE SPAN
    const phiStart = (min_lng / 90.0) * (Math.PI / 2.0);
    const phiLength = (Math.abs(max_lng - min_lng) / 90.0) * (Math.PI / 2.0);
    
    // LATITUDE SPAN
    const thetaStart = ((90.0 - max_lat) / 90.0) * (Math.PI / 2.0);
    const thetaLength = (Math.abs(max_lat - min_lat) / 90.0) * (Math.PI / 2.0);

    return { phiStart, phiLength, thetaStart, thetaLength };
}
