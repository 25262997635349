<script>
    
    // properties
    export let canvas;
    export let camera, renderer, scene;

    // import libraries 
    import * as THREE from 'three';
    import { onMount } from 'svelte';
    import { createNoise3D } from 'simplex-noise';
    const noise3D = createNoise3D();

    // --- Camera ---
    // fov — Camera frustum vertical field of view.
    export const fov = 75;

    // near — Camera frustum near plane.
    export const near = 0.1;

    // far — Camera frustum far plane.
    export const far = 1000;


    // --- Animated Plane ---
    const plane_side_length = 60;


    onMount(() => {

        // grab canvas
        canvas = document.querySelector('#canvas');
        
        // get bounding box size
        const width = window.innerWidth;
        const height = window.innerHeight;
        
        // compute camera parameters
        const aspect = width / height;
        
        // init elements
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera( fov, aspect, near, far );
        renderer = new THREE.WebGLRenderer({ canvas: canvas });
        renderer.setClearColor(0x000000);  // Set background color to black with no transparency
        
        // set renderer attributes
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );

        // update camera
        camera.position.set(0, 0, 8);
        camera.lookAt(0, plane_side_length, 0);

        // draw plane
        const geometry = new THREE.PlaneGeometry(plane_side_length, plane_side_length, plane_side_length * 3.0, plane_side_length * 3.0);
        const material = new THREE.LineBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.2 });
        const wireframe = new THREE.WireframeGeometry(geometry);
        const plane = new THREE.LineSegments(wireframe, material);
        scene.add(plane);

        // animate plane
        let time = 0;
        function animate() {
            requestAnimationFrame(animate);

            // increment time var
            time += 0.005;

            // update plane
            const position = plane.geometry.attributes.position;
            for (let i = 0; i < position.count; i++) {
                const x = position.getX(i);
                const y = position.getY(i);
                const z = noise3D(x * 0.2, y * 0.2, time) * 2;
                position.setZ(i, z);
            }
            position.needsUpdate = true;

            // update scene
            renderer.render(scene, camera);
        }

        // launch animation
        animate();
    })

</script>


<canvas id="canvas"></canvas>


<style>

    canvas {
        position: absolute;
        z-index: 0;
        background-color: #000;
        width: 100%;
        height: 100%;
    }
    
</style>